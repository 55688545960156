<template>
    <v-app class="grey">
        <v-main>
            <DiceRoller></DiceRoller>
        </v-main>
    </v-app>
</template>

<script>
    import DiceRoller from "./components/DiceRoller";

    export default {
        name: 'App',
        components: {
            DiceRoller
        }
    };
</script>

<style lang="scss">
    @import "styles/main";
</style>
